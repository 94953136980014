import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { useColorMode } from "theme-ui";

import Section from "@components/Section";
import SocialLinks from "@components/SocialLinks";

import mediaqueries from "@styles/media";
import Icons from "@icons";

import Hide from "@kiwicom/orbit-components/lib/Hide";

const WhatsappButton: React.FC<{}> = () => {
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const fill = isDark ? "#fff" : "#000";

  return (
    <IconWrapper
      isDark={isDark}
      data-a11y="false"
      aria-label="Envie-nos uma mensagem pelo whatsapp"
      title="Envie-nos uma mensagem pelo whatsapp"
    >
      <Icons.Whatsapp fill={fill}/>
    </IconWrapper>
  );
};

const MailButton: React.FC<{}> = () => {
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const fill = isDark ? "#fff" : "#000";

  return (
    <IconWrapper
      isDark={isDark}
      data-a11y="false"
      aria-label="Envie-nos um email"
      title="Envie-nos um email"
    >
      <Icons.Mail fill={fill}/>
    </IconWrapper>
  );
};

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
            social {
              url
              name
            }
          }
        }
      }
    }
    allMdx(sort: {fields: frontmatter___date, order: ASC}) {
      edges {
        node {
          frontmatter {
            date
          }
        }
      }
    }
  }
`;

const Footer: React.FC<{}> = () => {
  const results = useStaticQuery(siteQuery);
  const { name, social } = results.allSite.edges[0].node.siteMetadata;

  const copyrightDate = (() => {
    const { edges } = results.allMdx;
    const years = [0, edges.length - 1].map((edge) =>
      new Date(edges[edge].node.frontmatter.date).getFullYear()
    );
    return years[0] === years[1] ? `${years[0]}` : `${years[0]}–${years[1]}`;
  })();

  return (
    <>
      <FooterGradient />
      <Section narrow>
        <HoritzontalRule />
        <FooterContainer>
          <center style={{ padding: "40px 40px 0px 40px" }}>
            <strong>Escritórios</strong>
            <p style={{ paddingTop: "3px" }}>São Paulo, Brazil</p>
            <p style={{ paddingTop: "3px" }}>Lisbon, Portugal</p>
          </center>
          <center style={{ padding: "40px 40px 0px 40px" }}>
            <strong>Contatos</strong>
            <p style={{ paddingTop: "3px" }}><a style={{ color: "#73737D" }} href="https://api.whatsapp.com/send?1=pt_BR&phone=+5511999399423&text=Olá, estou interessado em saber mais informações." target="_blank">Whatsapp</a></p>
            <p style={{ paddingTop: "3px" }}><a style={{ color: "#73737D" }} href="https://xsensor.typeform.com/to/n2NhLg" target="_blank">Email</a></p>
          </center>
          <div style={{ padding: "40px 40px 0px 40px" }}>
            <SocialLinks links={social} />
          </div>
          <div style={{ padding: "40px 40px 0px 40px" }}>
            © {copyrightDate} {name}
          </div>
        </FooterContainer>
      </Section>
    </>
  );
};

export default Footer;

const IconWrapper = styled.button<{ isDark: boolean }>`
  opacity: 0.5;
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  margin-left: 30px;

  &:hover {
    opacity: 1;
  }

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.tablet`
    display: inline-flex;
    transform: scale(0.708);
    margin-left: 10px;


    &:hover {
      opacity: 0.5;
    }
  `}
`;

const FooterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 80px;
  color: ${p => p.theme.colors.grey};

  ${mediaqueries.tablet`
    flex-direction: column;
    padding-bottom: 100px;
  `}

  ${mediaqueries.phablet`
    padding-bottom: 50px;
  `}
`;

const HoritzontalRule = styled.div`
  position: relative;
  margin: 140px auto 50px;
  border-bottom: 1px solid ${p => p.theme.colors.horizontalRule};

  ${mediaqueries.tablet`
    margin: 60px auto;
  `}

  ${mediaqueries.phablet`
    display: none;
  `}
`;

const FooterText = styled.div`
  ${mediaqueries.tablet`
    margin-bottom: 80px;
  `}

  ${mediaqueries.phablet`
    margin: 120px auto 100px;
  `}
`;

const FooterGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${p => p.theme.colors.gradient};
  transition: ${p => p.theme.colorModeTransition};
`;
