import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link, navigate, graphql, useStaticQuery } from "gatsby";
import { useColorMode } from "theme-ui";

import Section from "@components/Section";
import Logo from "@components/Logo";

import Hide from "@kiwicom/orbit-components/lib/Hide";

import Icons from "@icons";
import mediaqueries from "@styles/media";
import {
  copyToClipboard,
  getWindowDimensions,
  getBreakpointFromTheme,
} from "@utils";

const siteQuery = graphql`
  {
    sitePlugin(name: { eq: "@narative/gatsby-theme-novela" }) {
      pluginOptions {
        rootPath
        basePath
      }
    }
  }
`;

const ProductsMenu: React.FC<{}> = () => {
  return (
    <Dropdown>
      <Dropbtn>Produtos</Dropbtn>
      <DropdownContent className="dropdownContent">
        <Link to="/c1"><DropdownContentA>Modelo C1</DropdownContentA></Link>
        <Link to="/c2"><DropdownContentA>Modelo C2</DropdownContentA></Link>
        <Link to="/c3"><DropdownContentA>Modelo C3</DropdownContentA></Link>
        <Link to="/dash"><DropdownContentA>Dash</DropdownContentA></Link>
      </DropdownContent>
    </Dropdown>
  );
};

const WhatsappButton: React.FC<{}> = () => {
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const fill = isDark ? "#fff" : "#000";

  return (
    <IconWrapper
      isDark={isDark}
      data-a11y="false"
      aria-label="Envie-nos uma mensagem pelo whatsapp"
      title="Envie-nos uma mensagem pelo whatsapp"
    >
      <Icons.Whatsapp fill={fill}/>
    </IconWrapper>
  );
};

const MailButton: React.FC<{}> = () => {
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const fill = isDark ? "#fff" : "#000";

  return (
    <IconWrapper
      isDark={isDark}
      data-a11y="false"
      aria-label="Envie-nos um email"
      title="Envie-nos um email"
    >
      <Icons.Mail fill={fill}/>
    </IconWrapper>
  );
};

const NavigationHeader: React.FC<{}> = () => {
  const [showBackArrow, setShowBackArrow] = useState<boolean>(true);
  const [previousPath, setPreviousPath] = useState<string>("/");
  const { sitePlugin } = useStaticQuery(siteQuery);

  const [colorMode] = useColorMode();
  const fill = colorMode === "dark" ? "#fff" : "#000";
  const { rootPath, basePath } = sitePlugin.pluginOptions;

  useEffect(() => {
    const { width } = getWindowDimensions();
    const phablet = getBreakpointFromTheme("phablet");

    const prev = localStorage.getItem("previousPath");
    const previousPathWasHomepage =
      prev === (rootPath || basePath) || (prev && prev.includes("/page/"));
    const isNotPaginated = !location.pathname.includes("/page/");

    setShowBackArrow(
      previousPathWasHomepage && isNotPaginated && width <= phablet,
    );
    setPreviousPath(prev);
  }, []);

  return (
    <Section>
      <NavContainer>
        <LogoLink
          to={rootPath || basePath}
          data-a11y="false"
          title="Navegar de volta à Página Inicial"
          aria-label="Navegar de volta à Página Inicial"
          back={showBackArrow ? "true" : "false"}
        >
          {showBackArrow && (
            <BackArrowIconContainer>
              <Icons.ChevronLeft fill={fill} />
            </BackArrowIconContainer>
          )}
          <Logo fill={fill} />
          <Hidden>Navegar de volta à Página Inicial</Hidden>
        </LogoLink>
        <NavControls>
          {showBackArrow ? (
            <button
              onClick={() => navigate(previousPath)}
              title="Navegar de volta à Página Inicial"
              aria-label="Navegar de volta à Página Inicial"
            >
              <Icons.Ex fill={fill} />
            </button>
          ) : (
            <>
            {/* <MenuLinkStyle><ProductsMenu/></MenuLinkStyle> */}
            <MenuLinkStyle><Link to="/produtos" style={{color:'#636363'}}>Produtos</Link></MenuLinkStyle>
            <MenuLinkStyle><Link to="/authors/xsensor" style={{color:'#636363'}}>Sobre</Link></MenuLinkStyle>
            <Hide on={["smallMobile","mediumMobile","largeMobile"]}>
              <a href="https://api.whatsapp.com/send?1=pt_BR&phone=+5511999399423&text=Olá, estou interessado em saber mais informações." target="_blank"><WhatsappButton/></a>
            </Hide>
            <Hide on={["smallMobile","mediumMobile","largeMobile"]}>
              <a href="https://xsensor.typeform.com/to/n2NhLg" target="_blank"><MailButton/></a>
            </Hide>
            </>
          )}
        </NavControls>
      </NavContainer>
    </Section>
  );
};

export default NavigationHeader;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const Dropbtn = styled.button`
  color: #636363;
  padding: 16px;
  font-size: 16px;
  border: none;

  &:hover + .dropdownContent{
    display: block;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

  &:hover{
    display: block;
  }
`;

const DropdownContentA = styled.div`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  &:hover {background-color: #ddd;}
`;

const MenuLinkStyle = styled.div`
  padding: 0 0 0 30px;
  color: #636363;
  &:hover {
    color: black;
  }

  ${mediaqueries.phone`
    padding: 0 0 0 5px;
  `}

`;

const BackArrowIconContainer = styled.div`
  transition: 0.2s transform var(--ease-out-quad);
  opacity: 0;
  padding-right: 30px;
  animation: fadein 0.3s linear forwards;

  @keyframes fadein {
    to {
      opacity: 1;
    }
  }

  ${mediaqueries.desktop_medium`
    display: none;
  `}
`;

const NavContainer = styled.div`
  position: relative;
  z-index: 100;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;

  ${mediaqueries.desktop_medium`
    padding-top: 50px;
  `};

  @media screen and (max-height: 800px) {
    padding-top: 50px;
  }
`;

const LogoLink = styled(Link)<{ back: string }>`
  position: relative;
  display: flex;
  align-items: center;
  left: ${p => (p.back === "true" ? "-54px" : 0)};

  ${mediaqueries.desktop_medium`
    left: 0
  `}

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -10%;
    top: -30%;
    width: 120%;
    height: 160%;
    border: 2px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  &:hover {
    ${BackArrowIconContainer} {
      transform: translateX(-3px);
    }
  }
`;

const NavControls = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${mediaqueries.phablet`
    right: -5px;
  `}
`;

const IconWrapper = styled.button<{ isDark: boolean }>`
  opacity: 0.5;
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  margin-left: 30px;

  &:hover {
    opacity: 1;
  }

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.tablet`
    display: inline-flex;
    transform: scale(0.708);
    margin-left: 10px;


    &:hover {
      opacity: 0.5;
    }
  `}
`;

const Hidden = styled.span`
  position: absolute;
  display: inline-block;
  opacity: 0;
  width: 0px;
  height: 0px;
  visibility: hidden;
  overflow: hidden;
`;
